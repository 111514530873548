const RegexPatterns = {
  lowercaseletters: '^[a-z]*$',
  lowercaseandnumbers: '^[a-z0-9]*$',
  lowercasenumbersandcomma: '^[,a-z0-9]*$',
  lowercasenumbersspacedash: '^[a-z0-9 -]*$',
  lowercasenumberscommaspacedash: '^[, a-z0-9-]*$',
  url: /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/,
};

export default RegexPatterns;
