import * as React from 'react';
import { Card } from '@shopify/polaris';

export default class Error extends React.Component {
  render() {
    return (
      <Card sectioned>
        <p align='center'>
          Apologies, but we do not seem to be able to identify something in the
          process. Please go back through Shopify Admin and try again.
        </p>
      </Card>
    );
  }
}
